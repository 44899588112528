import { Chrono } from "react-chrono";
import { chronoMode } from "../common/helpers/types";
import Translation from "../lang/Translation";

interface IChronoProps {
  items: any[];
  mode: chronoMode | chronoMode.vertical;
}
function Chronology({ items, mode }: IChronoProps) {
  return (
    <Chrono
      items={items}
      mode={mode}
      cardHeight={100}
      hideControls
      enableBreakPoint
      verticalBreakPoint={480}
      classNames={{
        card: "chronoCard",
        cardMedia: "my-card-media",
        cardSubTitle: "my-card-subtitle",
        cardText: "chronoText",
        cardTitle: "my-card-title",
        controls: "my-controls",
        title: "chronoTitle",
      }}
      theme={{
        primary: "#7e1aba",
        secondary: "#7e1aba",
        // cardBgColor: "yellow",
        // titleColor: "black",
        // titleColorActive: "red",
      }}
    >
      {items.map((_item, idx) => (
        <div className="chronoItem" key={idx}>
          <h2>
            <Translation messageKey={_item.job} />
          </h2>
          {_item.subTitle && (
            <h3>
              <Translation messageKey={_item.subTitle} />
            </h3>
          )}
          {_item.description && (
            <p>
              <Translation messageKey={_item.description} />
            </p>
          )}
        </div>
      ))}
    </Chrono>
  );
}

export default Chronology;
