import { FormattedMessage } from "react-intl";

interface ITranslationProps {
  messageKey: string;
  defaultMessage?: string;
  value?: any;
}

function Translation({ messageKey, defaultMessage, value }: ITranslationProps) {
  return (
    <FormattedMessage
      id={messageKey}
      defaultMessage={defaultMessage || `${messageKey}-missing`}
      values={value || undefined}
    />
  );
}

export default Translation;
