import {
  chronoItems,
  jobExperienceItems,
  stackConfig,
} from "../common/helpers/config";
import Chronology from "../components/Chronology";
import "../styles/aboutMe.scss";
import { chronoMode } from "../common/helpers/types";
import Translation from "../lang/Translation";

function AboutMePage() {
  return (
    <>
      <section className="aboutTextSection">
        <h1>
          <Translation messageKey="about" />
          <span>
            <Translation messageKey="me" />
          </span>
        </h1>
        <p>
          <Translation messageKey="aboutMeLongDescription" />
        </p>
      </section>
      <section className="techStackSection">
        <h1>
          <Translation messageKey="tech" />
          <span>
            <Translation messageKey="stack" />
          </span>
        </h1>
        <p>
          <Translation messageKey="techStackIntro" />
        </p>
        <div className="stack1">
          {stackConfig.stack1.map((item, idx) => (
            <div className="stackCard" key={idx}>
              <img src={item.img} alt="item" />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
        <p>
          <Translation messageKey="techStackIntro2" />
        </p>
        <div className="stack2">
          {stackConfig.stack2.map((item, idx) => (
            <div className="stackCard" key={idx}>
              <img src={item.img} alt="item" />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
        <p>
          <Translation messageKey="techStackTools" />
        </p>
        <div className="stack3">
          {stackConfig.stack3.map((item, idx) => (
            <div className="stackCard" key={idx}>
              <img src={item.img} alt="item" />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      </section>
      <section className="educationSection">
        <h2>
          <Translation messageKey="educationsTitle" />
          <span>
            <Translation messageKey="certificatesTitle" />
          </span>
        </h2>
        <div className="timelineContainer">
          <Chronology items={chronoItems} mode={chronoMode.tree} />
        </div>
      </section>
      <section className="jobExperienceSection darkBackground">
        <h2>
          <Translation messageKey="job" />
          <span>
            <Translation messageKey="experience" />
          </span>
        </h2>
        <div className="timelineContainer">
          <Chronology items={jobExperienceItems} mode={chronoMode.tree} />
        </div>
      </section>
    </>
  );
}

export default AboutMePage;
