import { projectsList } from "../common/helpers/config";
import Translation from "../lang/Translation";
import ProjectCard from "../partials/ProjectCard";
import "../styles/projects.scss";

function ProjectPage() {
  return (
    <section className="projectSection">
      <div className="container">
        <h1>
          <Translation messageKey="projectsPage" />
        </h1>
        <p>
          <Translation messageKey="projectsPageDescription" />
        </p>
        <div className="projectsList">
          {projectsList.map((project) => (
            <ProjectCard item={project} key={`project_${project.id}`} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProjectPage;
