import { useLocation, useNavigate } from "react-router-dom";
import { links } from "../common/helpers/config";
import "../styles/header.scss";
import { useCallback, useState } from "react";
import Translation from "../lang/Translation";
import LanguageSelector from "../lang/LanguageSelector";

function Header() {
  let navigate = useNavigate();
  let location = useLocation();
  const [open, setOpen] = useState(false);

  const handleOptionsClick = useCallback((value: string) => {
    setOpen(false);
    navigate(value);
    // eslint-disable-next-line
  }, []);

  const isActive = useCallback(
    (path: string) => {
      return location.pathname === path;
    },
    [location.pathname]
  );

  return (
    <>
      <header>
        <ul>
          <li key="logo" className="logo" onClick={() => navigate("/")}>
            <img src="/assets/logo.svg" alt="logo" />
          </li>
          {links.map((item, idx) => (
            <li
              key={idx}
              onClick={() => navigate(item.href)}
              className={`${isActive(item.href) ? "active" : ""}`}
            >
              <Translation messageKey={item.title} />
            </li>
          ))}
          <li className="languageSelectorItem">
            <LanguageSelector />
          </li>
        </ul>
      </header>
      <div className="responsiveMenuContainer">
        <img src="/assets/logo.svg" alt="logo" onClick={() => navigate("/")} />
        <div className="responsiveMenu">
          <LanguageSelector />
          <div
            className={`burgerMenu ${open ? "open" : ""}`}
            onClick={() => setOpen(!open)}
          >
            <div />
            <div />
            <div />
          </div>
          <ul className={`burgerMenuList ${open ? "open" : ""}`}>
            {links.map((item, idx) => (
              <li key={idx} onClick={() => handleOptionsClick(item.href)}>
                <span>
                  <Translation messageKey={item.title} />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
