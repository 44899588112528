import { useNavigate } from "react-router-dom";
import "../styles/home.scss";
import Translation from "../lang/Translation";
import GetInTouchSection from "../partials/GetInTouchSection";

function HomePage() {
  let navigate = useNavigate();

  return (
    <>
      <section className="heroSection">
        <div className="container">
          <div className="nameContainer">
            <h1>
              <Translation messageKey="welcome" />
              <span>
                <Translation messageKey="myName" />
              </span>
              <Translation messageKey="vagyok" />
            </h1>
            <div className="line"></div>
            <p className="job">
              <Translation messageKey="jobTitle" />
            </p>
          </div>
          <img src="/assets/typing.svg" alt="img" />
        </div>
      </section>
      <section className="programmerSection">
        <div className="container">
          <img src="/assets/programmer.svg" alt="img" />
          <div className="meContainer">
            <h1>
              <Translation messageKey="about" />
              <span>
                <Translation messageKey="me" />
              </span>
            </h1>
            <span className="aboutShortDescription">
              <Translation messageKey="aboutShortDescription" />
            </span>
            <button
              className="btn default"
              onClick={() => navigate("/aboutMe")}
            >
              <Translation messageKey="readMore" />
            </button>
          </div>
        </div>
      </section>
      <GetInTouchSection />
    </>
  );
}

export default HomePage;
