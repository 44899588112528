import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectType } from "../common/helpers/types";
import { projectsList } from "../common/helpers/config";
import Translation from "../lang/Translation";

function ProjectDetailsPage() {
  const params = useParams();
  const [item, setItem] = useState<ProjectType | undefined>();

  useEffect(() => {
    if (params.id) {
      const projectDetails = projectsList.find(
        (proj) => proj.id.toString() === params.id
      );
      setItem(projectDetails);
    }
  }, [params]);

  return (
    <section className="projectDetailsSection">
      <div className="container">
        {item?.title && (
          <h1>
            <Translation messageKey={item?.title} />
          </h1>
        )}
        <div className="content">
          <div className="imgDetailsContainer">
            <div className="imgContainer">
              <img
                src={item?.imgSrc}
                alt="projectImage"
                className="projectImg"
              />
            </div>
            <div className="details">
              {item?.type && (
                <div>
                  <h2>
                    <Translation messageKey="type" />
                  </h2>
                  <p>
                    <Translation messageKey={item.type} />
                  </p>
                </div>
              )}
              {item?.technologies && (
                <div>
                  <h2>
                    <Translation messageKey="usedTechnologies" />
                  </h2>

                  <ul className="technologiesList">
                    {item?.technologies.map((tech, idx) => (
                      <li key={idx}>{tech}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div>
                <h2>
                  <Translation messageKey="links" />
                </h2>
                <div className="btnContainer">
                  <a
                    className="btn secondary"
                    href={item?.gitHub}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/assets/stack/github.svg" alt="github" />
                    <span>GitHub</span>
                  </a>
                  <a
                    className="btn secondary"
                    href={item?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/website.svg"
                      alt="website"
                      loading="lazy"
                    />
                    <span>
                      <Translation messageKey="website" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="projectDetails">
            <h2>
              <Translation messageKey="projectDetails" />
            </h2>
            {item?.descriptionKey && (
              <p>
                <Translation messageKey={item.descriptionKey} />
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectDetailsPage;
