import { useMemo } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useLanguage } from "../context/LanguageContext";
import AppRoutes from "../routes/Routes";
import "../styles/app.scss";
import jsonHU from "../lang/hu.json";
import jsonEN from "../lang/en.json";
import { IntlProvider } from "react-intl";
import CookieConsentComponent from "../common/CookieConsentComponent";
import ScrollToTop from "../common/ScrollToTop";

function App() {
  const { activeLanguage } = useLanguage();

  const translations = useMemo(() => {
    switch (activeLanguage) {
      case "HU":
        return jsonHU;
      case "EN":
        return jsonEN;

      default:
        return jsonEN;
    }
  }, [activeLanguage]);

  return (
    <IntlProvider
      messages={translations}
      locale={activeLanguage}
      defaultLocale="EN"
    >
      <ScrollToTop />
      <div className="App">
        <Header />
        <div className="AppContent">
          <AppRoutes />
        </div>
        <Footer />
      </div>
      <CookieConsentComponent />
    </IntlProvider>
  );
}

export default App;
