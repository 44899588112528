import { useLanguage } from "../../context/LanguageContext";
import "../../styles/errors.scss";

function Page404() {
  const { activeLanguage } = useLanguage();
  return (
    <section className="page404Section">
      <img
        src={activeLanguage === "HU" ? "/assets/404HU.svg" : "/assets/404.svg"}
        alt="404"
      />
    </section>
  );
}

export default Page404;
