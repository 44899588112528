import { useRef, useCallback } from "react";
import emailjs from "@emailjs/browser";
import Translation from "../lang/Translation";
import "../styles/contact.scss";
import GetInTouchSection from "../partials/GetInTouchSection";

function ContactPage() {
  const formRef = useRef<HTMLFormElement>(null);
  const sendEmail = useCallback((e: any) => {
    e.preventDefault();

    if (formRef.current) {
      emailjs
        .sendForm(
          "service_xpprgvq",
          "template_ikwuhpi",
          formRef.current,
          "fF14lorRYGJPy7RCG"
        )
        .then(
          (result) => {
            // console.log(result.text);
            // console.log("message sent");
            formRef.current?.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  }, []);
  return (
    <div className="contactPage">
      <div className="container">
        <h1>
          <Translation messageKey="contactPageTitle" />
        </h1>
        <p>
          <Translation messageKey="contactDescription" />
        </p>
        <div className="wrapper">
          <form ref={formRef} onSubmit={sendEmail}>
            <div className="row">
              <label>
                <Translation messageKey="formName" />
              </label>
              <input type="text" name="user_name" required />
            </div>
            <div className="row">
              <label>
                <Translation messageKey="formEmail" />
              </label>
              <input type="email" name="user_email" required />
            </div>
            <div className="row">
              <label>
                <Translation messageKey="formMessage" />
              </label>
              <textarea name="message" required />
            </div>
            <button
              type="submit"
              className="btn submit"
              // disabled={formRef.current?.checkValidity()}
            >
              <Translation messageKey="send" />
            </button>
          </form>
          <img src="/assets/contact.svg" alt="contact" />
        </div>
      </div>
      <GetInTouchSection />
    </div>
  );
}

export default ContactPage;
