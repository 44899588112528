export const links = [
    { title: "home", href: "/" },
    // { title: "services", href: "/services" },
    // { title: "prices", href: "/pricing" },
    { title: "aboutMe", href: "/aboutMe" },
    { title: "projects", href: "/projects" },
    { title: "contact", href: "/contact" },
]

export const stackConfig = {
    stack1: [
        { title: "React", img: "assets/stack/react.svg" },
        { title: "Next.Js", img: "/assets/stack/next.svg" },
        { title: "TypeScript", img: "/assets/stack/typescript.svg" },
        { title: "JavaScript", img: "/assets/stack/javascript.svg" },
        { title: "HTML5", img: "/assets/stack/html5.svg" },
        { title: "Sass", img: "/assets/stack/sass.svg" },
        { title: "CSS3", img: "/assets/stack/css3.svg" },
        { title: "TailWindCSS", img: "/assets/stack/tailwind.svg" },
    ],
    stack2: [
        { title: "Angular4", img: "/assets/stack/angular.svg" },
        { title: "Laravel", img: "assets/stack/laravel.svg" },
        { title: "JigsawSSG", img: "/assets/stack/jigsawSSG.svg" },
    ],
    stack3: [
        { title: "VS Code", img: "/assets/stack/vscode.svg" },
        { title: "Figma", img: "/assets/stack/figma.svg" },
        { title: "Git", img: "/assets/stack/git.svg" },
        { title: "GitHub", img: "/assets/stack/github.svg" },
        { title: "JobCtrl", img: "/assets/stack/jc_logo.png" },
        { title: "MongoDb", img: "/assets/stack/mongo.svg" },
        { title: "JIRA", img: "/assets/stack/jira.svg" },
        { title: "Vercel", img: "/assets/stack/vercel.svg" },
        { title: "Postman", img: "/assets/stack/postman.svg" },
        { title: "Terminal", img: "/assets/stack/terminal.svg" },
    ]
}

export const chronoItems = [
    {
        title: "2016 - 2018",
        description: "schoolJedlik",
        job: "softwareDeveloperJob",
    },
    {
        title: "2017",
        description: "schoolJedlik",
        job: "CCNARoutingSwitchingCert",
    },
    {
        title: "2017",
        description: "schoolJedlik",
        job: "CITECert",
    },
    {
        title: "2014 - 2016",
        description: "schoolCity",
        job: "systemAdministratorJob",
    }

];
export const jobExperienceItems = [
    {
        title: `2023 - `,
        subTitle: "tctCompanyName",
        job: "frontendWork",
        description: "workTCTDescription"
    },
    {
        title: "2018 - 2023",
        subTitle: "mmsCompanyName",
        job: "frontendWork",
        description: "workMMSDescription"
    },
    {
        title: "2019",
        subTitle: "beautymuhelyCompanyName",
        job: "projectWork",
        description: "projectBeautyMuhely"
    },
    {
        title: "2018",
        subTitle: "fugediAndFugediCompanyName",
        job: "projectWork",
        description: "projectFugediAndFugedi"
    }

];

export const projectsList = [
    {
        id: 1,
        title: 'attrectoIntTestTitle',
        url: 'https://attrecto-test-app.vercel.app/',
        gitHub: "https://github.com/galgoroli96/attrecto_test_app",
        type: "testApp",
        descriptionKey: "attrectoTestDesc",
        imgSrc: "/assets/projectImages/attrectoApp.png",
        technologies: ["React", "TypeScript", "WebPack"]
    },
    {
        id: 2,
        title: 'tctIntTestTitle',
        url: 'https://tct-challenge.vercel.app/',
        gitHub: "https://github.com/galgoroli96/Tct-challenge",
        type: "testApp",
        descriptionKey: "tctTestDesc",
        imgSrc: "/assets/projectImages/tctApp.png",
        technologies: ["React", "TypeScript", "Vite"]
    },
    {
        id: 3,
        title: 'diligentIntTestTitle',
        url: 'https://movieligent.vercel.app/',
        gitHub: "https://github.com/galgoroli96/movieligent",
        type: "testApp",
        descriptionKey: "diligentTestDesc",
        imgSrc: "/assets/projectImages/movieligentApp.png",
        technologies: ["React", "TypeScript", "Vite"]
    }
]