import CookieConsent from "react-cookie-consent";
import { useIntl } from "react-intl";
import Translation from "../lang/Translation";
// https://www.npmjs.com/package/react-cookie-consent#using-it

function CookieConsentComponent() {
  const intl = useIntl();

  return (
    <CookieConsent
      location="bottom"
      buttonText={intl.formatMessage({ id: "cookieBtnText" })}
      declineButtonText={intl.formatMessage({ id: "cookieDeclineBtnText" })}
      cookieName="acceptAllCookies"
      style={{ background: "#00adb5" }}
      buttonStyle={{
        color: "white",
        fontSize: "14px",
        background: "#1d222a",
        borderRadius: "14px",
        padding: "10px 15px",
      }}
      declineButtonStyle={{
        color: "white",
        fontSize: "14px",
        background: "#a00c0c",
        borderRadius: "14px",
        padding: "10px 15px",
      }}
      enableDeclineButton
      flipButtons
      onDecline={() => {
        document.cookie =
          "acceptAllCookies=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.replace("https://www.google.com/");
      }}
      expires={20}
    >
      <span className="cookieDescription">
        <Translation messageKey="cookieDescription" />
      </span>
    </CookieConsent>
  );
}

export default CookieConsentComponent;
