import Translation from "../lang/Translation";
import "../styles/footer.scss";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <p>
        © {currentYear} <Translation messageKey="footerText" />
      </p>
    </footer>
  );
}

export default Footer;
