import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import Page404 from "../pages/errors/404";
import AboutMePage from "../pages/AboutMePage";
import ProjectPage from "../pages/ProjectPage";
import ProjectDetailsPage from "../pages/ProjectDetailsPage";
import ContactPage from "../pages/ContactPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/aboutMe" element={<AboutMePage />} />
      <Route path="/projects" element={<ProjectPage />} />
      <Route path="/projects/:id" element={<ProjectDetailsPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="*" element={<Navigate to="/error/404" />} />
      {/* <Route path="*" element={<Navigate to="/error/404" />} /> */}
      <Route path="/error/404" element={<Page404 />} />
    </Routes>
  );
}

export default AppRoutes;
