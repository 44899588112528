import { useNavigate } from "react-router-dom";
import { ProjectType } from "../common/helpers/types";
import Translation from "../lang/Translation";

interface IProjectCardProps {
  item: ProjectType;
}
function ProjectCard({ item }: IProjectCardProps) {
  let navigate = useNavigate();

  return (
    <div className="projectCard">
      <figure>
        <img src={item.imgSrc} alt="projectImg" loading="lazy" />
        <span className="projectType">
          <Translation messageKey={item.type} />
        </span>
      </figure>
      <article>
        <h2>
          <Translation messageKey={item.title} />
        </h2>
        <button
          className="btn default"
          onClick={() => navigate(`/projects/${item.id}`)}
        >
          <Translation messageKey="readMore" />
        </button>
      </article>
    </div>
  );
}

export default ProjectCard;
