import { useLanguage } from "../context/LanguageContext";
import Translation from "../lang/Translation";
import "../styles/partialStyles.scss";

function GetInTouchSection() {
  const { activeLanguage } = useLanguage();
  return (
    <section className="getInTouch">
      <div className="container">
        <h2 className={activeLanguage === "HU" ? "flex" : ""}>
          <Translation messageKey="getIn" />
          <span>
            <Translation messageKey="touch" />
          </span>
        </h2>
        <p>
          <Translation messageKey="contactMe" />
        </p>
        <div className="connections">
          <a
            href="mailto:roland.developer96@gmail.com"
            className="connectionItem"
          >
            <img src="/assets/mail.svg" alt="mail" />
            <span>E-mail</span>
          </a>
          <a
            href="https://github.com/galgoroli96"
            target="_blank"
            rel="noreferrer"
            className="connectionItem"
          >
            <img src="/assets/stack/github.svg" alt="github" />
            <span>Github</span>
          </a>
          <a
            href="https://www.linkedin.com/in/rolandgalgoczi/"
            target="_blank"
            rel="noreferrer"
            className="connectionItem"
          >
            <img src="/assets/linkedin.svg" alt="linkedIn" />
            <span>LinkedIn</span>
          </a>
          {/* <a
            href="https://twitter.com/"
            target="_blank"
            rel="noreferrer"
            className="connectionItem"
          >
            <img src="/assets/twitter.svg" alt="twitter" />
            <span>Twitter</span>
          </a> */}
        </div>
      </div>
    </section>
  );
}

export default GetInTouchSection;
