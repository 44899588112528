export enum chronoMode { vertical = 'VERTICAL', horizontal = 'HORIZONTAL', tree = 'VERTICAL_ALTERNATING' };

export type ProjectType = {
    id: number;
    title: string;
    url: string;
    gitHub: string;
    type: string;
    imgSrc?: string;
    descriptionKey?: string;
    technologies: string[];
}