import { createContext, ReactNode, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

type LanguageProviderProps = {
  children: ReactNode;
};

type LanguageContextType = {
  activeLanguage: string;
  changeActiveLanguage: (value: string) => void;
};

const LanguageContext = createContext({} as LanguageContextType);

export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }: LanguageProviderProps) {
  const [activeLanguage, setActiveLanguage] = useLocalStorage(
    "activeLang",
    "EN"
  );

  function changeActiveLanguage(value: string) {
    setActiveLanguage(value);
  }

  return (
    <LanguageContext.Provider value={{ activeLanguage, changeActiveLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
